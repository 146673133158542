<template>
  <v-container fluid tag="section">
    <v-layout align-center justify-center>
        <v-row class="justify-center">
            <v-col cols="12" class="text-right" v-if="role==UserRole.Admin">
                <dialog-message
                    :dialog_message="dialog_message"
                    :message="message"
                ></dialog-message>
                <v-dialog v-model="dialog_form" max-width="500px" @click:outside="close">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on" @click="createProduct">
                        <v-icon left>mdi-pencil-plus</v-icon>
                        {{ $t("shopping.createProduct") }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline" v-if="newProduct">{{ $t("shopping.createProduct") }}</span>
                            <span class="headline" v-if="!newProduct">{{ $t("shopping.updateProduct") }}</span>
                        </v-card-title>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card-text>
                                <v-container>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="item.name"
                                                :rules="fieldRules"
                                                :label="$t('shopping.name')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="item.price"
                                                :rules="fieldRules"
                                                :label="$t('shopping.price')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="item.icon"
                                                :rules="fieldRules"
                                                :label="$t('shopping.icon')"
                                                prefix="mdi-"
                                                :append-outer-icon="'mdi-'+item.icon"
                                                @click:append-outer="searchIcon"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                :label="$t('shopping.sort')"
                                                :rules="sortRules"
                                                class="purple-input"
                                                v-model="item.sort"
                                            />
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-checkbox
                                                v-model="item.is_test"
                                                :label="$t('shopping.is_test')"
                                            ></v-checkbox>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-select
                                                v-model="item.product_type"
                                                :label="$t('product.type')"
                                                :items="ProductTypeItems"
                                                item-value="value"
                                                hide-details
                                            >
                                                <template slot="selection" slot-scope="data">
                                                    {{ $t(data.item.text) }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    {{ $t(data.item.text) }}
                                                </template>
                                            </v-select>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-textarea
                                                :label="$t('shopping.descriptions')"
                                                :rules="fieldRules"
                                                class="purple-input"
                                                v-model="item.descriptions"
                                                no-resize
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                {{
                                $t("common.cancel")
                                }}
                            </v-btn>
                            <v-btn v-if="newProduct" color="blue darken-1" text :disabled="loading" @click="save">
                                {{
                                $t("common.save")
                                }}
                            </v-btn>
                            <v-btn v-if="!newProduct" color="blue darken-1" text @click="update">
                                {{
                                $t("common.save")
                                }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <template v-for="(item, index) in purchaseItems">
                <v-col :key="index" cols="3" class="mt-4">
                    <v-card :color="item.is_test==true?'orange':''" class="my-0 d-flex flex-column justify-space-between product" height="100%" min-height="400px" min-width="150px">
                        <v-card-title class="mb-10 pt-5">
                            <v-spacer/>
                            <span class="text-h3">
                                {{ item.name }}
                                <v-icon v-if="role==UserRole.Admin" @click="updateProduct(item)">mdi-pencil</v-icon>
                            </span>
                            <v-spacer/>
                        </v-card-title>
                        <v-card-text class="text-center">
                            <v-avatar
                                size="80"
                            >
                                <v-icon class="outlined" size="60">
                                    <template v-if="item.icon">
                                        {{ item.icon }}
                                    </template>
                                    <template v-else>
                                        mdi-basket
                                    </template>
                                </v-icon>
                            </v-avatar>
                            <div class="text-h3 px-2 py-4">
                                ${{ item.price }}
                            </div>
                            <div class="text-h4 px-2 py-4">
                                {{ item.descriptions }}
                            </div>
                        </v-card-text>
                        <v-card-actions class="mb-3">
                            <v-spacer/>
                            <template v-if="role==UserRole.Admin">
                                <v-btn color="red" class="mr-0 text-h5 purchase" @click="removeProduct(item)">
                                    <v-icon class="mr-1">mdi-cart-minus</v-icon>
                                    {{ $t('common.delete') }}
                                </v-btn>
                            </template>
                            <template v-else>
                                <v-btn color="primary" class="mr-0 text-h5 purchase" @click="addToCart(item)">
                                    <v-icon class="mr-1">mdi-cart-plus</v-icon>
                                    {{ $t('shopping.purchase') }}
                                </v-btn>
                            </template>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-layout>
  </v-container>
</template>

<script>

import { mapState, mapMutations } from "vuex";
import { UserRole, ProductType, ProductTypeItems } from '@/definition'
export default ({
    data: (vm) => ({
        UserRole: UserRole,
        ProductType: ProductType,
        ProductTypeItems: ProductTypeItems,
        loading: false,
        dialog_message: false,
        message: {
            title: "",
            text: ""
        },
        newProduct: false,
        dialog_form: false,
        valid: true,
        purchaseItems: [],
        productId: null,
        item: {
            name : "",
            price : 0,
            icon : "basket",
            sort : 0,
            is_test : false,
            descriptions : "",
            product_type: ProductType.Urban,
        },
        fieldRules: [v => !!v || vm.$i18n.t("common.missing")],
        sortRules: [v => v > 0 || vm.$i18n.t("common.aboveZero")],
    }),
    computed: {
        ...mapState(["token", "role"])
    },
    mounted(){
        this.getApi()
    },
    methods:{
        ...mapMutations({
            addShoppingItems: "addShoppingItems",
        }),
        close() {
            this.dialog_form = false;
            this.loading = false;
        },
        getApi() {
        this.loading = true;
        const currentObj = this;
        // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const url = process.env.VUE_APP_SERVER_URL + "/backend/product/";
        let config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + this.token
            },
        };

        this.axios
            .get(url, config)
            .then(function(response) {
            currentObj.loading = false;
            currentObj.setResult(response.data);
            })
            .catch(function(error) {
            console.log(error);
            console.log(error.response);
            currentObj.loading = false;
            currentObj.message.title = currentObj.$t("get-api-fail");
            currentObj.message.text = JSON.stringify(error.response.data);
            currentObj.dialog_message = !currentObj.dialog_message;
            });
        },
        setResult(data){
            this.purchaseItems = data.sort((a,b)=>{ return a.sort - b.sort })
        },
        searchIcon(){
            window.open('https://materialdesignicons.com/', '_blank')
        },
        createProduct(){
            if(this.$refs.form){
                this.$refs.form.resetValidation()
            }
            this.newProduct = true
            this.productId = null
            this.item.name = ""
            this.item.price = 0
            this.item.icon = "basket"
            this.item.sort = 0
            this.item.is_test = true
            this.item.product_type = this.ProductType.Urban
            this.item.descriptions = ""
        },
        updateProduct(data){
            this.newProduct = false
            this.productId = data.id
            this.item.name = data.name
            this.item.price = data.price
            this.item.icon = data.icon.replace('mdi-', '')
            this.item.sort = data.sort
            this.item.is_test = data.is_test
            this.item.product_type = data.product_type
            this.item.descriptions = data.descriptions
            this.dialog_form = !this.dialog_form
        },
        save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            // create hn account
            this.loading = true;
            const currentObj = this;
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const url = process.env.VUE_APP_SERVER_URL + "/backend/product/";
            let config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "token " + this.token
                }
            };
            let data = {
                name : this.item.name,
                price : this.item.price,
                icon : "mdi-"+this.item.icon,
                sort : this.item.sort,
                is_test : this.item.is_test,
                product_type: this.item.product_type,
                descriptions : this.item.descriptions,
            };
            this.axios
                .post(url, data, config)
                .then(function(response) {
                currentObj.loading = false;
                alert("送出成功");
                currentObj.getApi();
                currentObj.close();
                })
                .catch(function(error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("save-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        update() {
            if (!this.$refs.form.validate()) {
                return;
            }
            // create hn account
            this.loading = true;
            const currentObj = this;
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const url =
                process.env.VUE_APP_SERVER_URL + "/backend/product/" + this.productId + "/";
            let config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "token " + this.token
                }
            };
            let data = {
                name : this.item.name,
                price : this.item.price,
                icon : "mdi-"+this.item.icon,
                sort : this.item.sort,
                is_test : this.item.is_test,
                product_type: this.item.product_type,
                descriptions : this.item.descriptions,
            };
            this.axios
                .patch(url, data, config)
                .then(function(response) {
                currentObj.loading = false;
                alert("送出成功");
                currentObj.getApi();
                currentObj.close();
                })
                .catch(function(error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("update-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        removeProduct(item){
            if(!confirm(
                this.$i18n.t('common.confirm') + this.$i18n.t('common.delete')+ "？"
                )
            ) {
                return;
            }
            const currentObj = this;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/product/" +
                item.id +
                "/remove/";
            const config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + this.token
                }
            };
            this.axios
                .patch(url, {}, config)
                .then(function(response) {
                alert("刪除成功。");
                    currentObj.getApi();
                })
                .catch(function(error) {
                    console.log(error);
                    console.log(error.response);
                    currentObj.loading = false;
                    currentObj.message.title = currentObj.$t("remove-fail");
                    currentObj.message.text = JSON.stringify(error.response.data);
                    currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        addToCart(product){
            this.addShoppingItems(product)
        }
    }
})
</script>
<style lang="sass" scoped>
.outlined
    border: 0.5px solid black
    border-radius: 50%

.v-card.product
    &:hover
        background: linear-gradient(#49ABEB, #0174C0)
        .v-card__title
            color: #ffffff
        .v-card__text
            color: #ffffff
        .outlined
            color: #ffffff
            border: 0.5px solid #ffffff
</style>
